<template>
	<v-card>
		<v-card-title class="height">
			<v-row no-gutters>
				<v-col>
					<v-text-field
						:disabled="carregando"
						v-model="busca.busca"
						label="Buscar"
						outlined
						dense
						@keydown.enter="listar(0)"
					/>
				</v-col>
				<v-col cols="auto" class="ml-2">
					<v-btn
						:disabled="carregando"
						class="mt-1"
						color="primary"
						elevation="0"
						fab
						x-small
						@click="listar(0)"
					>
						<v-icon>mdi-magnify</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-card-title>
		<v-divider />
		<v-progress-linear indeterminate absolute :active="carregando" />
		<v-card-text>
			<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
			<v-simple-table v-else>
				<thead>
					<tr>
						<th>ID</th>
						<th>Notificação</th>
						<th>
							<v-btn
								block
								small
								color="red darken-3"
								outlined
								class="px-1"
								@click="marcarLida({idusuario: usuario.idusuario})"
							>Marcar todas como lida</v-btn>
						</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="dados.total > 0">
						<tr v-for="(d,i) in dados.lista" :key="i">
							<td>
								<v-btn
									small
									color="primary"
									outlined
									block
									@click="carregar(`${d.caminho}${d.idchamado}`, {idnotificacao: d.idnotificacao, idusuario: usuario.idusuario})"
								>
									<v-icon>mdi-eye-plus-outline</v-icon>
								</v-btn>
							</td>
							<td :class="!d.lido ? 'font-weight-bold' : ''" v-html="d.notificacao"></td>
							<td class="text-center">
								<v-btn
									small
									color="primary"
									outlined
									:disabled="d.lido"
									block
									@click="marcarLida({idnotificacao: d.idnotificacao, idusuario: usuario.idusuario})"
								>Marcar como lida</v-btn>
							</td>
						</tr>
					</template>
					<template v-else>
						<tr>
							<td class="text-center" colspan="3">Nenhum registro encontrado.</td>
						</tr>
					</template>
				</tbody>
			</v-simple-table>
			<Paginacao
				:total="dados.total"
				@atualizar="listar"
				:carregando="carregando"
				:pgLimitInt="pgLimitInt"
				:pg="pgSelecionada"
			/>
		</v-card-text>
		<v-progress-linear indeterminate absolute :active="carregando" />
	</v-card>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Paginacao from "../Widgets/Paginacao";

export default {
	name: "ChamadoNotificacoes",
	components: { Paginacao },
	data: () => ({
		dados: {},
		busca: {},
		carregando: false,
		carregandoSkeleton: true,
		pgLimitInt: 5,
		pgSelecionada: 0,
	}),
	computed: {
		...mapState(["backendUrl", "usuario"]),
	},
	methods: {
		listar(pg) {
			this.pgSelecionada = pg;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}chamado/notificacao/listar`, {
					limit: this.pgLimitInt,
					offset: this.pgLimitInt * pg,
					busca: this.busca.busca || null,
					idusuario: parseInt(this.usuario.idusuario),
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
					this.carregandoSkeleton = false;
					setTimeout(() => {
						this.listar(0);
					}, 60000);
				})
				.catch(() => {
					this.dados = { total: 0 };
					this.carregando = false;
					this.carregandoSkeleton = false;
				});
		},
		marcarLida(obj) {
			let rota = obj.idnotificacao ? "lida" : "todas-lidas";
			return axios
				.post(`${this.backendUrl}chamado/notificacao/${rota}`, obj)
				.then((res) => {
					if (res.data) {
						if (obj.idnotificacao) {
							this.dados.lista.forEach((v) => {
								if (v.idnotificacao == obj.idnotificacao) {
									v.lido = true;
								}
							});
						} else {
							this.dados.lista.forEach((v) => (v.lido = true));
						}
					}
				});
		},
		carregar(url, obj) {
			this.marcarLida(obj);
			setTimeout(() => {
				this.$router.push(url);
			}, 500);
		},
	},
	created() {
		this.listar(0);
	},
};
</script>

<style scoped>
.height {
	height: 64px;
}
</style>